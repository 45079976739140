.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-label {
    font-weight: 500;
    margin-bottom: 10px;
    color: #374151;
}

input.form-control {
    padding: 10px 10px;
    font-size: 16px;
    border-width: 1px;
    outline: none;
}

input[type="color"] {
    padding: 0;
    width: 20px;
    height: 23px;
    border-width: 0;
}
